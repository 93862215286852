import axios from "axios";

export const getMenu = () => async dispatch => {
    dispatch({type: 'GET_MENU_REQUEST'})

    try {
        const response = await axios.get("/api/menu/getmenu")
        console.log(response);
        dispatch({type: "GET_MENU_SUCCESS", payload : response.data})
    } catch(error) {
        dispatch({type: "GET_MENU_FAILED", payload : error})
    }
}
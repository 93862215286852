import { combineReducers } from "@reduxjs/toolkit";
import { configureStore, applyMiddleware } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { getMenuReducer } from "./reducers/menuReducers";

const finalReducer = combineReducers({
    getMenuReducer : getMenuReducer
})

const initialState = {}
const composeEnhancers = composeWithDevTools({});

const store = configureStore({reducer: finalReducer}, initialState, composeEnhancers(applyMiddleware(thunk)));

export default store;
import React from 'react';
import Button from './elements/Button';
import { Link } from "react-router-dom";

const Banner = () => {
    return (
        <div className='bg-tomato'>
            <div className="container mx-auto pb-32 pt-48 relative flex flex-wrap items-center justify-between">
                <div className="banner-deescription text-center w-full md:w-1/2 p-3">
                    <h2 className=" mb-6 text-5xl font-bold text-white">
                        Authentic & Halal
                    </h2>
                    <p className="font-semibold text-2xl text-white py-2">
                        All your favourite Indian dishes in one place.
                    </p>
                    <div className="btn-container py-4">
                        <Button>
                            <Link to='https://order.online/business/chick-n-biryani-11827186' className="text-xl text-white">Order Now</Link>
                        </Button>
                    </div>
                </div>
                <div className="banner-image md:w-1/2 p-3 flex justify-end z-10">
                    <img src={require("../assets/img/Chicken_Biryani.png")} alt="banner" className="justify-items-center max-h-96" />
                </div>
            </div>
        </div>
    )
}

export default Banner
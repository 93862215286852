import React from 'react'
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Header  from '../Components/Header';
import Home from '../pages/Home';
import Cart from '../pages/Cart';
import Payment from '../pages/Payment';
import Menu from '../pages/Menu';
import Contact from '../pages/Contact';

const Navigation = () => {
  return (
    <BrowserRouter>
        <Header />
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="https://order.online/business/chick-n-biryani-11827186" element={<Menu />} />
            <Route path="/payment" element={<Payment />} />
        </Routes>
    </BrowserRouter>
  )
}

export default Navigation;
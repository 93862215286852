import ProductDetails from './ProductDetails';
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import image1 from '../assets/img/Image1.jpg';
import image2 from '../assets/img/Image2.jpg';
import image3 from '../assets/img/Image3.jpg';
import image4 from '../assets/img/Image4.jpg';
import image5 from '../assets/img/Image5.jpg';
import {useDispatch} from "react-redux";

const MostPopular = () => {

    const dispatch = useDispatch();

    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };

    const items = [
        {
            title: "Chicken Biryani",
            price: "$11.99",
            imgUrl: image1,
        },
        {
            title: "Veal Biryani",
            price: "$12.99",
            imgUrl: image2,
        },
        {
            title: "Butter Chicken",
            price: "$9.99",
            imgUrl: image3,
        },
        {
            title: "Chicken Curry",
            price: "$9.99",
            imgUrl: image4,
        },
        {
            title: "Veal Curry",
            price: "$9.99",
            imgUrl: image5,
        },
    ];


  return (
    <div className='container mx-auto pt-8 pb-4 w-2/3 bg-white'>
        <h1 className='text-tomato font-bold text-2xl'>POPULAR ITEMS</h1>
        <Carousel responsive={responsive} className='z-10'>
            {
                items.map((item, index) => {
                    return (
                        <ProductDetails
                            key={index}
                            {...item}
                        />
                    )
                })
            }
        </Carousel>
    </div>
    )
}

export default MostPopular
import { useState } from "react";
import Logo from "../assets/img/Logo.png";
import { Link } from "react-router-dom";
import { FiShoppingCart } from "react-icons/fi";
import { FaBars } from "react-icons/fa6";
import { FaXmark } from "react-icons/fa6";



const Header = () => {

    let [open, setOpen] = useState(false);

    return (
        <nav id="header" className='shadow-md w-full fixed z-20 bg-white top-0 left-0'>
            <div className="md:flex items-center justify-between py-4 md:px-10 px-7">
                <div className="cursor-pointer flex items-center">
                    <Link to='/' className="toggleColor no-underline hover:no-underline">
                        <img src={Logo} alt="Chick N Biryani Logo" className="w-20"/>
                    </Link>
                </div>
                <div onClick={()=>setOpen(!open)} className="absolute right-8 top-6 cursor-pointer md:hidden w-7 h-7 ">
                    {
                        open ? <FaXmark /> : <FaBars />
                    }
                </div>
                <div className={`md:flex md:items-center md:pb-0 pb-12 absolute md:static bg-white md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-500 ease-in ${open ? 'top-12' : 'top-[-490px]'}`} >
                    <Link to='/' className="px-2 py-1 block rounded md:ml-8 md:my-0 my-7 font-semibold text-neutral-500 bg-white hover:bg-neutral-100">Home</Link>
                    <Link to='/contact' className="px-2 py-1 block rounded md:ml-8 md:my-0 my-7 font-semibold text-neutral-500 bg-white hover:bg-neutral-100">Contact</Link>
                    <Link to='https://order.online/business/chick-n-biryani-11827186' className="px-2 py-1 block rounded md:ml-8 md:my-0 my-7 font-semibold text-neutral-500 bg-white hover:bg-neutral-100">Menu</Link>
                    {/*
                    <div className="px-2 py-1 block rounded md:ml-8 md:my-0 my-7 font-semibold text-neutral-500 bg-white hover:bg-neutral-100">
                        <Link to='/cart'><FiShoppingCart /></Link>
                    </div>
                    */}
                </div>
            </div>
        </nav>
    )
}

export default Header;
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getMenu } from "../../actions/menuActions";
import MenuCard from "../../Components/MenuCard";
import Footer from "../../Components/Footer";

const Menu = () => {

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getMenu())
  }, [])

  return (
    <>
      <div className="container mx-auto flex flex-wrap">
        <MenuCard />
      </div>
      <Footer />
    </>
  )
}

export default Menu

export const getMenuReducer = (state = {}, action) => {
    
    switch(action.type) {
        case 'GET_MENU_REQUEST' : return {
            ...state
        }
        case 'GET_MENU_SUCCESS' : return {
            menu : action.payload
        }
        case 'GET_MENU_FAILED' : return {
            error : action.payload
        }
        default : return state
    }
}
import ProductDetails from './ProductDetails';
import image1 from '../assets/img/Image1.jpg';
import image2 from '../assets/img/Image2.jpg';
import image3 from '../assets/img/Image3.jpg';
import image4 from '../assets/img/Image4.jpg';
import image5 from '../assets/img/Image5.jpg';

import React from 'react'

const MenuCard = () => {

    const categoryPointFive = [
        {
            title: "Chicken Tikka (5pc)",
            price: "$12.99",
            imgUrl: image1,
        },
        {
            title: "Chicken 65",
            price: "$12.99",
            imgUrl: image2,
        },
        {
            title: "Beef Samosa (3pc)",
            price: "$4.99",
            imgUrl: image2,
        },
        {
            title: "Chicken Samosa (3pc)",
            price: "$4.99",
            imgUrl: image2,
        },
        {
            title: "Veggie Samosa (5pc)",
            price: "$4.99",
            imgUrl: image2,
        },
        {
            title: "Chicken Rolls (3pc)",
            price: "$4.99",
            imgUrl: image2,
        },
    ];

    const categoryOne = [
        {
            title: "Chicken Biryani",
            price: "$11.99",
            imgUrl: image1,
        },
        {
            title: "Veal Biryani",
            price: "$12.99",
            imgUrl: image2,
        },
        {
            title: "Lamb Biryani",
            price: "$16.99",
            imgUrl: image3,
        },
        {
            title: "Chicken Tikka Biryani",
            price: "$14.99",
            imgUrl: image3,
        },
    ];

    const categoryTwo = [
        {
            title: "Chicken Curry",
            price: "$9.99",
            imgUrl: image1,
        },
        {
            title: "Veal Curry",
            price: "$9.99",
            imgUrl: image2,
        },
        {
            title: "Butter Chicken",
            price: "$9.99",
            imgUrl: image3,
        },
        {
            title: "Chicken Tikka Masala",
            price: "$10.99",
            imgUrl: image3,
        },
        {
            title: "Chicken Handi",
            price: "$12.99",
            imgUrl: image3,
        },
    ];

    const categoryThree = [
        {
            title: "Chicken Tenders (3pc)",
            price: "$7.99",
            imgUrl: image1,
        },
        {
            title: "Chicken Tenders (6pc)",
            price: "$10.99",
            imgUrl: image2,
        },
        {
            title: "Chicken Nuggets (6pc)",
            price: "$5.99",
            imgUrl: image3,
        },
        {
            title: "Chicken Nuggets (12pc)",
            price: "$11.99",
            imgUrl: image3,
        },
        {
            title: "Chicken Sandwich",
            price: "$7.99",
            imgUrl: image3,
        },
        {
            title: "Chicken Burger",
            price: "$6.99",
            imgUrl: image3,
        },
        {
            title: "Chicken Wrap",
            price: "$4.99",
            imgUrl: image3,
        },
        {
            title: "Popcorn Chicken",
            price: "$8.99",
            imgUrl: image3,
        },
    ];

    const categoryFour = [
        {
            title: "Chicken Tikka w/ Rice",
            price: "$15.99",
            imgUrl: image1,
        },
        {
            title: "Chicken Fried Rice",
            price: "$10.99",
            imgUrl: image2,
        },
        {
            title: "Vegeterian Fried Rice",
            price: "$8.99",
            imgUrl: image3,
        },
        {
            title: "Plain Rice",
            price: "$4.99",
            imgUrl: image3,
        },
    ];

    const categoryFive = [
        {
            title: "Regular Naan",
            price: "$0.99",
            imgUrl: image1,
        },
        {
            title: "Butter Naan",
            price: "$1.49",
            imgUrl: image2,
        },
        {
            title: "Garlic Naan",
            price: "$1.49",
            imgUrl: image3,
        },
    ];

    const categorySix = [
        {
            title: "Masala Chai",
            price: "$2.49",
            imgUrl: image4,
        },
        {
            title: "Soft Drink (Can)",
            price: "$1.49",
            imgUrl: image1,
        },
        {
            title: "Soft Drink (Bottle)",
            price: "$2.49",
            imgUrl: image2,
        },
        {
            title: "Water Bottle",
            price: "$0.99",
            imgUrl: image3,
        },
    ];

    return (
        <div className='container mx-auto py-32 w-2/3 bg-white'>
            <h1 className='text-tomato font-bold text-2xl text-center'>OUR MENU</h1>

            <h2 className='text-tomato font-semibold text-xl pt-20'>Appetizers</h2>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
                {
                    categoryPointFive.map((item, index) => {
                        return (
                            <ProductDetails
                                key={index}
                                {...item}
                            />
                        )
                    })
                }
            </div>

            <h2 className='text-tomato font-semibold text-xl pt-20'>Biryani</h2>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
                {
                    categoryOne.map((item, index) => {
                        return (
                            <ProductDetails
                                key={index}
                                {...item}
                            />
                        )
                    })
                }
            </div>

            <h2 className='text-tomato font-semibold text-xl pt-20'>Curry Specials</h2>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
                {
                    categoryTwo.map((item, index) => {
                        return (
                            <ProductDetails
                                key={index}
                                {...item}
                            />
                        )
                    })
                }
            </div>

            <h2 className='text-tomato font-semibold text-xl pt-20'>Fried Fun</h2>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
                {
                    categoryThree.map((item, index) => {
                        return (
                            <ProductDetails
                                key={index}
                                {...item}
                            />
                        )
                    })
                }
            </div>

            <h2 className='text-tomato font-semibold text-xl pt-20'>Rice</h2>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
                {
                    categoryFour.map((item, index) => {
                        return (
                            <ProductDetails
                                key={index}
                                {...item}
                            />
                        )
                    })
                }
            </div>

            <h2 className='text-tomato font-semibold text-xl pt-20'>Naan</h2>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
                {
                    categoryFive.map((item, index) => {
                        return (
                            <ProductDetails
                                key={index}
                                {...item}
                            />
                        )
                    })
                }
            </div>

            <h2 className='text-tomato font-semibold text-xl pt-20'>Drinks</h2>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
                {
                    categorySix.map((item, index) => {
                        return (
                            <ProductDetails
                                key={index}
                                {...item}
                            />
                        )
                    })
                }
            </div>

        </div>
    )
}

export default MenuCard
import Banner from "../../Components/Banner";
import About from "../../Components/About";
import MostPopular from "../../Components/MostPopular";
import Testimonial from "../../Components/Testimonial";
import Footer from "../../Components/Footer";

const Home = () => {
  return (
    <>
        <Banner />
        <MostPopular />
        <About />
        <Testimonial />
        <Footer />
    </>
  )
}

export default Home;
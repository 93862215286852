import AddProduct from "./AddProduct";


const ProductDetails = ({ title, price, imgUrl, product, onAddProduct }) => {

    const addProduct = () => {
        onAddProduct(product)
    }

    return (
        <div className="p-4 m-4 bg-neutral-50 drop-shadow-md">
            <div className="w-full flex items-center justify-center">
                <img src={imgUrl} className="w-full object-cover pb-4" alt='Best Indian Food In Scarborough' />
            </div>
            <div className="flex flex-col items-center justify-between pb-2">
                <h2 className="text-neutral-800 text-xl">{title}</h2>
            </div>
            <div className="flex flex-col items-center justify-between pb-3">
                <div className="text-1xl text-neutral-600">{price}</div>
            </div>
            {/* <AddProduct onAddProduct={addProduct} /> */}
        </div>
    )
}

export default ProductDetails
import React from 'react'
import abtImage from "../assets/img/Image1.jpg"

const About = () => {
  return (
    <div className='bg-white container mx-auto px-20 py-20 items-center justify-center'>
        <div className='rounded-2xl pt-10 pb-10 px-8 sm:px-20 shadow-lg bg-marigold grid grid-cols-1 lg:grid-cols-2'> 
            <div className='text-justify pr-4'>
                <h2 className='p-2 text-2xl font-bold text-white'>
                    Our Story
                </h2>
                <p className='p-2 pt-8 text-sm text-white font-semibold'>
                Welcome to Chick N Biryani, where the heart of India meets Toronto's vibrant culinary scene. 
                Our story is one of family, flavor, and a deep-rooted passion for sharing the authentic taste of 
                India with our community.
                </p>

                <p className='p-2 pt-8 text-sm text-white font-semibold'>
                We believe that food is a bridge that connects us to our roots. Our philosophy revolves around crafting 
                dishes that not only tantalize the taste buds but also evoke a sense of nostalgia for those who yearn 
                for the flavours of home.
                </p>

                <p className='p-2 pt-8 text-sm text-white font-semibold'>
                Our menu is a carefully curated selection of Indian delights, featuring a symphony of spices, 
                the freshest ingredients, and the expertise of our culinary artisans. Whether you're savoring our 
                signature biryanis, indulging in succulent chicken dishes, or exploring vegetarian 
                options, every dish is a testament to our commitment to authenticity.
                </p>

            </div>
            <div className='p-2 flex items-center justify-center'>
                <img src={abtImage} alt='' className='rounded-2xl w-100 h-100'/>
             </div>
        </div>
    </div>
  )
}

export default About
import { useState } from 'react';
import { IoPersonCircleSharp } from "react-icons/io5";
import { ImQuotesLeft } from "react-icons/im";
import { BiSolidRightArrowSquare } from "react-icons/bi";
import { BiSolidLeftArrowSquare } from "react-icons/bi";


const Testimonial = () => {

    const testimonials = [
        {
            name: "Steve Ronald",
            review: "We ordered Chicken Biryani & Chicken 65. The portion size was good, food was very fresh and was presented well! The Chicken 65 pieces were really juicy and the chicken pieces in the biryani too were really really soft and easily pull apart. Overall amazing food. Highly recommend!",
        },
        {
            name: "Saad Saleem",
            review: "First time trying this new restaurant, I had their veal biryani and it was amazing! Just one glance and you can see the crispy onions and fresh cilantro, a clear sign they put effort into their dishes. It had plenty of super tender meat and the rice was so fluffy and perfectly seasoned. Hats off to the chef! I've got my eyes on their chicken biryani for my next visit. Definitely coming back for more!"     
        },
        {
            name: "Faujan Patel",
            review: "Taste wise it was amazing. First bite reminded me the taste of home. It was so delicious. As fast as quantity is concert any adult can eat 2 times in just $10."
        },
        {
            name: "Sunil Gandhi",
            review: "I tried chicken sandwich and nuggets for the first time in this restaurant and I must say it was so tasty and juicy. Chicken nuggets were perfect in size and so flavourful and portion size was really good."
        },

    ];



    const [current, setCurrent] = useState(0);
    const length = testimonials.length;

    const previous = () => {
        setCurrent(current === 0 ? length -1 : current - 1)
    }

    const next = () => {
        setCurrent(current === length -1 ? 0 : current + 1)
    }

    return (
        <section className='container mx-auto p-20 pb-40 flex justify-center items-center'>
            <div className='bg-white shadow-xl p-20 rounded'>
                {
                    testimonials.map((item, index) => index === current && <div key={index}
                    >

                        <div className='pb-[20px] flex flex-wrap items-start gap-4'>
                            <ImQuotesLeft className='text-6xl text-marigold'/>
                            <p className='text-lg font-semibold text-marigold lowercase'>{item.review}</p>
                        </div>

                        <div className='flex gap-4 items-center'>
                            <div className=''>
                                <IoPersonCircleSharp  className='text-tomato'/>
                            </div>

                            <div>
                                <h2 className='font-light text-tomato'>{item.name}</h2>
                            </div>

                            <div className='flex gap-2'>
                                <div className='text-tomato text-opacity-75 hover:text-opacity-100 cursor-pointer text-4xl'>
                                    <BiSolidLeftArrowSquare onClick={previous}/>
                                </div>

                                <div className='text-tomato text-opacity-75 hover:text-opacity-100 cursor-pointer text-4xl'>
                                    <BiSolidRightArrowSquare onClick={next}/>
                                </div>
                            </div>

                        </div>

                       

                    </div>)
                }
            </div>
        </section>
    )
}

export default Testimonial
import React from 'react'
import { FaPhone } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";
import { FaLocationDot } from "react-icons/fa6";


const Contact = () => {
  return (
    <div class="bg-marigold flex w-full min-h-screen justify-center items-center pt-28">
      <div className='flex flex-col md:flex-row md:space-x-6 space-y-6 md:space-y-0  justify-between max-w-4xl p-8 rounded-xl text-white overflow-hidden'>
        
        <div className='px-20'>
          <div className='flex flex-col space-y-8 justify-between'>
            <h1 className='font-bold text-4xl tracking-wide uppercase'>Contact Us</h1>
            <p className='pb-2 font-semibold'>Please contact us regarding catering or special orders for a large group.</p>
          </div>

          <div className='flex flex-col space-y-4'>
            <div className='inline-flex space-x-2 items-center pt-10'>
              <FaPhone />
              <span className='px-2'>(416) 264-0707</span>
            </div>

            <div className='inline-flex space-x-2 items-center pt-2'>
              <IoIosMail />
              <span className='px-2'>order@chicknbiryani.com</span>
            </div>

            <div className='inline-flex space-x-2 items-center pt-2'>
              <FaLocationDot />
              <span className='px-2'>286 Markham Rd, Scarborough ON, M1J 3C5</span>
            </div>
          </div>
        </div>

        <div className='relative'>
          <div className='absolute w-40 h-40 bg-tomato rounded-full z-0 -right-28 -top-28'></div>
          <div className='absolute w-40 h-40 bg-tomato rounded-full z-0 -left-28 -bottom-28'></div>

        <div className='relative z-10 bg-white rounded-xl shadow-lg sm:p-12 p-16 md:w-80'>
          <form action="" class="flex flex-col space-y-4">
            <div>
              <label for="" className="text-sm text-neutral-600">Your Name</label>
              <input type="text" 
                placeholder='Your Name' 
                class="ring-1 ring-gray-300 w-full rounded-md mt-2 px-4 
                py-2 outline-none focus:ring-2 focus:ring-marigold text-black">
              </input>
            </div>
            
            <div>
              <label for="" className="text-sm text-neutral-600">Email Address</label>
              <input type="text" 
                placeholder='Email Address' 
                class="ring-1 ring-gray-300 w-full rounded-md mt-2 px-4 
                py-2 outline-none focus:ring-2 focus:ring-marigold text-black">
              </input>
            </div>
            <div>
            <label for="" className="text-sm text-neutral-600">Message</label>
              <textarea type="text" 
                placeholder='Email Address' 
                rows="4"
                class="ring-1 ring-gray-300 w-full rounded-md mt-2 px-4 
                py-2 outline-none focus:ring-2 focus:ring-marigold text-black">
              </textarea>
            </div>
            <button class="inline-block self-end bg-marigold opacity-70 hover:opacity-100 text-white font-bold rounded-lg px-6 py-2 uppercase text-sm">Send Message</button>
          </form>
        </div>
        </div>
      </div>
    </div>
  )
}

export default Contact
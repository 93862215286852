import React from 'react'
import { Link } from "react-router-dom";
import { FaInstagram } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FaPhone } from "react-icons/fa6";
import logo from '../assets/img/Logo.png';

const Footer = () => {
  return (
    <div className='bg-tomato py-4 mt-10'>
      <div className='container mx-auto px-8 justify-between flex flex-col md:flex-row'>
        <div className='flex justify-center items-center py-2'>
          <img src={logo} alt="Chick N Biryani" className='px-2 w-20' />
          <div className='justify-center items-center px-4'>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2882.2998065152005!2d-79.22237419365469!3d43.745870646175774!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d4d1f7405489e9%3A0xae91df4f41006333!2sChick%20N%20Biryani!5e0!3m2!1sen!2sca!4v1702058490039!5m2!1sen!2sca"
              width="150"
              height="150"
              style={{ border: "0" }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              className='justify-center items-center'>
            </iframe>
          </div>
        </div>

        <div class="flex flex-col md:flex-row py-2 items-center">
          <Link to="https://www.tiktok.com/@chicknbiryani" className='py-2 px-4 text-neutral-200 hover:text-white'><FaInstagram /></Link>
          <Link to="https://www.instagram.com/chickn.biryani/" className='py-2 px-4 text-neutral-200 hover:text-white'><FaTiktok /></Link>
          <Link to="/contact" className='py-2 px-4 text-neutral-200 hover:text-white'><MdEmail /></Link>
          <Link to="tel:416-264-0707" className='py-2 px-4 text-neutral-200 hover:text-white'><FaPhone /></Link>
        </div>
      </div>
    </div>
  )
}

export default Footer